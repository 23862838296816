<template>
  <ChartJSBar
    ref="chart"
    :class="{
      'pointer-events-none': noInteractions,
    }"
    :title="title"
    :chart-data="chartData"
    :dataset-id-key="datasetIdKey"
    :chart-options="config"
    :styles="{ width: '100%', height: '100%' }"
    :width="0"
    :height="chartHeight"
    tabindex="0"
    :aria-label="title"
    role="graphics-object"
    :aria-roledescription="$t('Wcag.BarChart')"
  />
</template>

<script>
import { Bar as ChartJSBar } from 'vue-chartjs/legacy'
import { mergeObjects } from '@/utils/object'
import '@/utils/charts'

export default {
  components: { ChartJSBar },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    chartOptions: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    showXLabels: {
      type: Boolean,
      default: false,
    },
    showYLabels: {
      type: Boolean,
      default: false,
    },
    showXBorder: {
      type: Boolean,
      default: false,
    },
    showYBorder: {
      type: Boolean,
      default: false,
    },
    showXIntraLines: {
      type: Boolean,
      default: false,
    },
    showYIntraLines: {
      type: Boolean,
      default: false,
    },
    mirrorYLabels: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    dashedLines: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    noInteractions: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: undefined,
    },
    chartHeight: {
      type: Number,
      default: 0
    },
    ticksColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      renderComponent: false,
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets.map((dataset) => mergeObjects({}, dataset)),
      }
    },
    config() {
      const defaultConfig = {
        responsive: true,
        aspectRatio: this.aspectRatio,
        maintainAspectRatio: !!this.aspectRatio,
        indexAxis: this.horizontal ? 'y' : 'x',
        categoryPercentage: 0.6,
        barPercentage: 1,
        plugins: {
          title: {
            display: !!this.title,
            text: this.title,
            padding: {
              bottom: 20
            }
          },
          legend: {
            display: this.showLegend
          },
        },
        scales: {
          x: {
            stacked: this.stacked,
            grid: {
              drawBorder: this.showXBorder,
              drawOnChartArea: this.showXIntraLines,
              drawTicks: false,
              borderDash: this.dashedLines ? [2, 2] : undefined,
            },
            ticks: {
              padding: 12,
              display: this.showXLabels,
              color: this.ticksColor
            },
            title: {
              color: this.ticksColor
            }
          },
          y: {
            stacked: this.stacked,
            grid: {
              drawBorder: this.showYBorder,
              drawOnChartArea: this.showYIntraLines,
              drawTicks: false,
              borderDash: this.dashedLines ? [2, 2] : undefined,
            },
            ticks: {
              mirror: this.mirrorYLabels,
              padding: this.mirrorYLabels ? 4 : 12,
              display: this.showYLabels,
              z: 1,
              color: this.mirrorYLabels ? '#fff' : this.ticksColor,
              font: this.mirrorYLabels
                ? {
                    size: 14,
                  }
                : undefined,
            },
            title: {
              color: this.ticksColor
            }
          },
        },
      }
      return mergeObjects(defaultConfig, this.chartOptions || {})
    },
  },
  watch: {
    $props: {
      handler() {
        // force chart refresh
        this.$refs.chart.renderChart(this.chartData, this.config)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
