<template>
  <div
    :class="{
      pagination: true,
      disabled,
    }"
  >
    <div class="pagination-settings">
      <div class="pagination-select">
        <FormGroup horizontal :html-for="`perPageOptions${uuid}`" :label="$t('Components.PerPage')">
          <Select
            :id="`perPageOptions${uuid}`"
            :show-filter="false"
            :options="perPageOptions"
            :value="`${itemsPerPage}`"
            :context="context"
            placement="top-start"
            auto-width
            :disabled="disabled"
            @input="(perPage) => selectPage(page, +perPage)"
          />
        </FormGroup>
      </div>
    </div>

    <div class="pagination-buttons">
      <Button :aria-label="$t('Wcag.PaginationFirstPage')" variant="ghost" :disabled="disabled || page === 1" @click="selectPage(1, itemsPerPage)">
        <Icon name="chevronDoubleRight" class="rotate-180" />
      </Button>
      <Button :aria-label="$t('Wcag.PaginationPrevPage')" variant="ghost" :disabled="disabled || page === 1" @click="selectPage(page - 1, itemsPerPage)">
        <Icon name="chevronRight" class="rotate-180" />
      </Button>
      <Button :aria-label="$t('Wcag.PaginationNextPage')" variant="ghost" :disabled="disabled || page === pages" @click="selectPage(page + 1, itemsPerPage)">
        <Icon name="chevronRight" />
      </Button>
      <Button :aria-label="$t('Wcag.PaginationLastPage')" variant="ghost" :disabled="disabled || page === pages" @click="selectPage(pages, itemsPerPage)">
        <Icon name="chevronDoubleRight" />
      </Button>
    </div>

    <span v-if="totalItems > 0" class="pagination-details">
      <span>{{ visibleItemsStart }}</span>
      <span>-</span>
      <span>{{ visibleItemsEnd }}</span>
      <span>di</span>
      <span>{{ totalItems }}</span>
    </span>

    <div class="pagination-mobile">
      <div class="space-x-px">
        <Button :aria-label="$t('Wcag.PaginationFirstPage')" :disabled="disabled || page === 1" @click="selectPage(1, itemsPerPage)">
          <Icon name="chevronDoubleRight" class="rotate-180" />
        </Button>
        <Button :aria-label="$t('Wcag.PaginationPrevPage')" :disabled="disabled || page === 1" @click="selectPage(page - 1, itemsPerPage)">
          <Icon name="chevronRight" class="rotate-180" />
        </Button>
      </div>
      <span class="numbers">
        {{ page }}
      </span>
      <div class="space-x-px">
        <Button :aria-label="$t('Wcag.PaginationNextPage')" :disabled="disabled || page === pages" @click="selectPage(page + 1, itemsPerPage)">
          <Icon name="chevronRight" />
        </Button>
        <Button :aria-label="$t('Wcag.PaginationLastPage')" :disabled="disabled || page === pages" @click="selectPage(pages, itemsPerPage)">
          <Icon name="chevronDoubleRight" />
        </Button>
      </div>
    </div>

  </div>
</template>

<script>
import Button from './Button.vue'
import Icon from './Icon.vue'
import Select from './Select.vue'

let uuid = 0;

export default {
  components: { Button, Icon, Select },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      required: false
    },
    perPageOptionValues: {
      type: Array,
      default: () => ['5', '10', '20', '50', '100']
    }
  },
  emits: ['page-change'],
  data() {
    return {
      perPageOptions: this.perPageOptionValues.map((n) => ({ value: n, label: n })),
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    visibleItemsStart() {
      return (this.page - 1) * this.itemsPerPage + 1
    },
    visibleItemsEnd() {
      return Math.min(this.totalItems, (this.page - 1) * this.itemsPerPage + this.itemsPerPage)
    },
  },
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  methods: {
    selectPage(page, perPage) {
      this.$emit('page-change', perPage !== this.itemsPerPage ? 1 : page, perPage)
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  @apply flex flex-wrap items-center md:space-x-5;
  @apply text-sm text-primary-300;
}

.pagination-settings,
.pagination-buttons,
.pagination-details {
  @apply hidden md:flex items-center space-x-2;
}

.pagination-select {
  @apply flex items-center;
}

.pagination-details {
  @apply -mb-1; // font adjustment
}

.pagination-mobile {
  @apply flex md:hidden justify-between items-center w-full;
  .numbers {
    @apply text-primary-700 font-bold
  }
}

</style>

<style lang="scss" global>
.pagination {
  &.disabled {
    .pagination-select {
      .select,
      .input-wrapper-prefix,
      .input-wrapper-suffix {
        @apply bg-transparent;
      }
    }
  }
}

.pagination-select {
  .input-wrapper {
    @apply border-0 bg-transparent;
    @apply ml-1;
  }

  .input-wrapper-prefix,
  .input-wrapper-suffix {
    @apply w-4;
  }

  .input-wrapper,
  .input-wrapper-prefix,
  .input-wrapper-suffix,
  .select {
    @apply h-7;
  }
}
</style>
